import { CSSProperties } from 'react';

export function Cart({
  width,
  ...rest
}: {
  width: number | string;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width={width}
      viewBox="0 0 408 368"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M294.5 229.5L262 335.5M294.5 229.5H236M294.5 229.5L307.175 187.125M407.5 9.5L377.315 3.3952C369.207 1.7553 361.185 6.56684 358.814 14.4924L345.202 60M345.202 60L259.026 68.625M345.202 60L332.526 102.375M153 229.5L262 335.5M153 229.5H179.5M153 229.5H113M262 335.5H80.5M172.851 77.25L179.5 229.5M172.851 77.25L259.026 68.625M172.851 77.25L86.6755 85.875M179.5 229.5H236M259.026 68.625L236 229.5M86.6755 85.875L21.4497 92.4032C10.9199 93.4571 4.29554 104.26 8.13109 114.123L13.625 128.25M86.6755 85.875L113 229.5M113 229.5H63.9451C57.3465 229.5 51.4246 225.449 49.033 219.299L39.875 195.75M26.75 162L319.851 144.75M26.75 162L13.625 128.25M26.75 162L39.875 195.75M319.851 144.75L332.526 102.375M319.851 144.75L307.175 187.125M13.625 128.25L332.526 102.375M39.875 195.75L307.175 187.125"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <circle cx="82" cy="352" r="15.5" stroke="currentColor" />
      <circle cx="267" cy="352" r="15.5" stroke="currentColor" />
    </svg>
  );
}
